 @font-face {
  font-family: "BeVietnamPro";
  src: local("BeVietnamPro"),
    url("../assets/fonts/BeVietnamPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "BeVietnamProBold";
  src: local("BeVietnamProBold"),
    url("../assets/fonts/BeVietnamPro-Bold.ttf") format("truetype");
}

 :root{
     --font_regular: "BeVietnamPro";
     --font_bold: "BeVietnamProBold";
 }