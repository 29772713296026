@import url("../../styles/constants.css");

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid white;
    border-top: 10px solid #c96634;
    border-radius: 50%;
    animation: spinner 0.75s linear infinite;
    visibility: hidden;
}

.loading-spinner.show {
    visibility: visible;
}

.backdrop.show {
    opacity: 1;
    pointer-events: visible;
}

.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Adjust as needed */
}

.loading-logo {
    animation: spinAndZoom 1s linear infinite;
    width: 30px; /* Adjust as needed */
}

.loading-logo-small {
    animation: spinAndZoom 2s linear infinite;
    height: 10px; /* Adjust as needed */
}

.loading-peppol {
    animation: smallZoom 3s linear infinite;
    height: 16px; /* Adjust as needed */
}

@keyframes smallZoom {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.95);
    }
}

@keyframes spinAndZoom {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}