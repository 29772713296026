@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --scroll_primary: #F15A29;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,400;0,700;0,900;1,400;1,700&display=swap');

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(to bottom,
      transparent,
      rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
  overflow-x: hidden;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  padding: 0;
  color: #0C1C37;
  /* font-family: 'Be Vietnam Pro'; */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary) var(--white);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

*::-webkit-scrollbar-track {
  background: var(--white);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll_primary);
  border-radius: 14px;
  border: 1px solid var(--primary);
}